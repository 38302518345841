.vision-box {
    display: flex;
    flex-direction: row; }

#file-upload {
    padding: 0 0 0 0;
    font-size: 14px;
    border: white;
    color: black;
    font-weight: 400;
    font-size: sans-serif; }


#upload-button {
 }        // border: white
