.planpage-containter {
  width: 100vw;
  max-width: 100%; }


.planpage-cover {
  height: calc(70vh + 80px);
  width: 100%;
  background-color: yellow; }


// /* <--- MAIN Section ---> */
.planpage-main {
  position: relative;
  height: calc(100vh - 80px);
  width: 100%; }


// /* Side Bar - width: 300px */
.planpage-sidebar {
  height: calc(100vh - 80px);
  width: 300px;
  float: left;
  display: flex;
  flex-direction: column;
  .planpage-sidebar-searchbar {
    width: 100%;
    height: 60px;
    background-color: white;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
  .planpage-sidebar-menu {
    width: 100%;
    height: calc( 100% - 60px );
    background-color: pink; } }
.planpage1-map {
  height: 100%;
  width: 100%; }

.planpage2-map {
  height: calc(100vh - 80px);
  float: left; }

.page-btn-bg {
  height: 50px;
  width: 50px;
  background-color: lightpink;
  text-decoration: none;
  border: none;
  border-radius: 50%;
  outline: none;
  padding: 0;
  font-size: 50px;
  vertical-align: middle;
  position: absolute;
  bottom: 30px; }


.page-btn-bg:active {
  background-color: rgb(219, 150, 160); }


.planpage-pre-btn {
  right: 125px; }


.planpage-next-btn {
  right: 40px; }


.page-btn {
  background: transparent;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 5px;
  color: white;
  font-size: 40px; }


.fa-caret-left {
  left: 14px; }


.fa-caret-right {
  right: 14px; }

.section-btn-bg {
  position: absolute;
  right: 40px;
  bottom: 30px;
  z-index: 1;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif;
  font-weight: 700;
  padding: 10px 22px;
  border-width: 2px;
  box-shadow: none;
  background: #FF5A5F;
  border-color: transparent;
  color: #ffffff;
  text-align: center;
  &:active {
    background-color: #9b383c; } }
