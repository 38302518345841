.footer-container {
  width: 100%;
  color: white;
  padding: 48px 24px;
  display: inline-block; }

.footer-container-content {
  display: flex;
  flex-direction: row;
  justify-content: center; }

.footer-box {
  position: relative;
  width: 246px;
  padding: 0 10px;
  display: inline-block;
  text-align: left;
  vertical-align: top; }

.footer-box ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 16px; }

.footer-box li {
  margin-bottom: 4px; }

.footer-container-bottom {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid rgba(74, 74, 74, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: center; }

.footer-container-bottom-left {
  text-align: left; }

.footer-container-bottom-left span {
  margin-left: 15px; }

