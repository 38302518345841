$airbnb-font: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif;
.checkout-page {
    margin-top: 160px;
    .checkout-page-content {
        margin: 0px 80px;
        padding: 10px 80px;
        min-height: calc( 100vh - 469px );
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .checkout-page-title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            font-family: $airbnb-font;
            margin-bottom: 10px;
            .checkout-page-title-name {
                font: inherit;
                font-weight: bold; }
            .checkout-page-title-date {
                font: inherit; }
            .checkout-page-title-option {
                font: inherit; } }
        .checkout-page-overviews {
            width: 100%; }
        .checkout-page-checkout {
            width: 100%;
            height: 200px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            .checkout-page-checkout-est {
                margin-bottom: 20px; }
            .checkout-page-checkout-btn {
                margin-bottom: 30px; } } }
    .footer {
        width: 100vw;
        max-width: 100%;
        text-align: center;
        background-color: black; }
    .checkout-page-modal-wrapper {} }

.modal-content-a {
    border: 0px;
    font-size: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100px;
    .modal-content-success {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center; } }
.modal-actions {
    width: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .modal-actions-text {
        &:hover {
            cursor: pointer;
            text-decoration: underline; } } }

.airbnb-bold {
    font-size: 18px;
    font-family: $airbnb-font;
    font-weight: bold; }
.airbnb-font {
    font-size: 18px;
    font-family: $airbnb-font; }

#pdf-button {
    margin-right: 20px; }
