.navbar-left-section {
  display: flex;
  flex-direction: row; }

.nav-logo {
  font-size: 200%;
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 80px; }

.navbar-container {
  width: 100vw;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  .navbar-left-section {
    flex-grow: 1;
    .navbar-middle {
      background: transparent;
      margin: 0;
      height: 80px;
      width: 100%;
      &:active {
        background-color: lightgray;
        opacity: 0.5; } } }
  .navbar-right-section {
    height: 80px;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0px;
    padding-right: 15px;
    .navbar-toggle-wrapper {
      // When collapsed, make togglebtn centered
      @media screen and ( max-width: 767px ) {
        height: 76px;
        width: 108px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center; }
      // Override collapsed here
      .collapsed {} }
    .nav-link {
      height: 100%;
      color: inherit;
      padding: 0;
      text-align: center;
      line-height: 79px;
      // sovle color change after module closed
      &:focus {
        color: inherit; }
      .nav-btn {
        height: 100%;
        color: inherit;
        font-size: 1.1em;
        font-weight: bold;
        letter-spacing: 1px;
        margin: 0 10px;
        padding: 0 5px;
        text-transform: uppercase;
        &:hover {
          color: black;
          border-bottom: 2px solid black; }
        &:active {
          color: #484848;
          border-bottom: 2px solid #484848; } }
      .user-logo-box {
        height: 78px;
        color: inherit;
        display: flex;
        flex-direction: row;
        align-items: center;
        .user-logo {
          width: 55px;
          height: 55px; }
        .user-text {
          margin-left: 15px; }
        &:hover {
          height: 80px;
          color: black;
          border-bottom: 2px solid black; }
        &:active {
          color: #484848;
          border-bottom: 2px solid #484848; } } } } }

.navbar-right {
  padding-right: 10px; }

@media only screen and (max-width: 768px) {
  .navbar-middle {
    display: none; } }

.navbar-middle {
  display: flex;
  flex-direction: row;
  .navbar-mid-left {
    .navbar-mid-title {
      height: 100%;
      text-align: left;
      overflow: hidden;
      font-size: 25px;
      font-weight: bold;
      line-height: 80px;
      border-left: 10px solid black;
      padding-left: 10px;
      color: black;
      span {
        font-size: 20px;
        font-weight: 400; } } }
  .navbar-mid-right {
    overflow: hidden; } }

.navbar-mid-info {
  padding-left: 12px;
  color: black;
  line-height: 80px; }

