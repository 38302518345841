.content-category {
  margin: 0 71.5px 80px 71.5px;
  padding: 0 80px; }

.content-category-header {
  margin-bottom: 24px;
  background-color: white; }


.content-category-header h3 {
  font-weight: 800;
  margin-bottom: 0px;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: normal;
  font-family: sans-serif;
  color: rgb(72, 72, 72);
  padding-top: 2px;
  padding-bottom: 2px; }


.content-category-header span {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: normal;
  font-family: sans-serif;
  color: rgb(72, 72, 72);
  font-weight: normal; }


.cardDeck {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  flex-wrap: wrap;
  margin: 0; }
