@import url('https://fonts.googleapis.com/css?family=Kanit:700');
@import url('https://fonts.googleapis.com/css?family=Roboto:500');
@import url('https://fonts.googleapis.com/css?family=Pacifico');

.title {
    font-family: 'Kanit', sans-serif;
    text-transform: capitalize;
    font-size: 3em;
    font-weight: 300;
    padding-left: 170px; }


.input-container {
    position: relative;
    margin-left: 70px;
    margin-top: 40px;
    margin-bottom: 50px;
    padding-bottom: 0px; }


.password-container {
    position: relative;
    margin-left: 70px;
    margin-top: -10px;
    margin-bottom: 50px;
    padding-bottom: 0px; }

input, button {
    letter-spacing: 1.5px; }

input {
    border: 1px solid;
    border-color: lighten(#979797, 30%);
    border-radius: 7px;
    width: 85%;
    padding: 10px 6px 10px 36px;
    font-size: 19px; }




.button-container {
    padding-left: 20%;
    padding-bottom: 20px;
    justify-content: center; }

.submit-button {
    background: lighten(#e5195f, 10%);
    color: white;
    border: 1px;
    width: 20rem;
    height: 4rem;
    font-family: 'Roboto', sans-serif;
    // border: none
    font-size: 20px;
    padding: 8px 16px;
    //cursor: pointer
    transition: all .3s;
    border-radius: 20px;
    //margin-bottom: -40px
    transition: all .3s;
    justify-content: center; }

.submit-button:hover {
    background: lighten(#e5195f, 1%); }


::placeholder {
    opacity: .4; }

#user-icon {
    position: absolute;
    color: grey;
    font-size: 1.5rem;
    left: 6px;
    top: 45%;
    transform: translateY(-50%); }

#password-icon {
    position: absolute;
    color: grey;
    font-size: 1.5rem;
    left: 6px;
    top: 45%;
    transform: translateY(-50%); }


#email-icon {
    position: absolute;
    color: grey;
    font-size: 1.5rem;
    left: 6px;
    top: 48%;
    transform: translateY(-50%); }


#header-signup {
    font-size: 2.5rem;
    font-family: 'Pacifico', cursive;
    padding-left: 12rem;
    padding-top: 0.5rem; }

#header-login {
    font-size: 2.5rem;
    font-family: 'Pacifico', cursive;
    padding-left: 8.5rem;
    padding-top: 0.5rem; }

#modal-footer {
    justify-content: center;
    display: flex; }

#facebookIcon {
    background-image: url("https://image.flaticon.com/icons/svg/145/145802.svg");
    background-repeat: no-repeat;
    width: 5rem;
    height: 3rem; }


#twitterIcon {
    background-image: url("https://image.flaticon.com/icons/svg/145/145812.svg");
    background-repeat: no-repeat;
    width: 5rem;
    height: 3rem; }

#check-box {
    margin-top: -3rem;
    padding-top: 20px;
    padding-left: 70px;
    padding-bottom: 30px; }

#check-type {
    width: 2rem;
    height: 1.1rem; }

#check-text {
    font-size: 20px;
    padding-left: 30px;
    margin-top: -25px; }

#reset-pw {
    padding-left: 40px;
    font-size: 1.3rem; }

#reset-pw:hover {
    color: #3e64ff; }
#checkbox-account {
    margin-top: -4rem;
    padding-top: 20px;
    padding-left: 190px;
    padding-bottom: 20px; }

#check-account {
    font-size: 1.3rem; }

#check-account:hover {
    color: #3e64ff; }
#googleIcon {
    background-image: url("https://image.flaticon.com/icons/svg/145/145804.svg");
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem; }
