.homepage-containter {
  width: 100vw;
  max-width: 100%;
  .flight-card-holder {
    max-width: 1024px;
    background: #878787;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto; } }

.homepage-header {
  position: relative;
  width: 100%; }



.homepage-footer {
  width: 100vw;
  max-width: 100%;
  text-align: center;
  background-color: black; }


.content-category-containter {
  width: 100vw;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; }

