.cards-holder {
    width: 100%;
    height: 100%;
    background: white;
    overflow: hidden;
    overflow-y: scroll;
    .card-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; } }
